
































import { Component, Vue } from 'vue-property-decorator';
import EntriesCard from '@/components/cards/EntriesCard.vue';
import { Action } from 'vuex-class';
import * as types from '@/store/main/types';

@Component({ components: { EntriesCard } })
export default class KoppelingAlgemeen extends Vue {

  @Action('getCaoTypes', { namespace: 'main' }) private getCaoTypes!: types.GetCaoTypesAction;

  private loadingCaos = false;
  private entries: Array<{ code: string, name: string, sbi: string }> = [];
  private searchFunction = (entry: { name: string, code: string }, search: string) => {
    return entry.name.toLowerCase().includes(search) || entry.code.includes(search);
  }

  private created() {
    this.loadingCaos = true;
    this.getCaoTypes({ in_api: true }).then((resp) => {
      this.entries = resp;
    }).finally(() => this.loadingCaos = false );
  }
}
